<template>
    <div>
        <strong>Order Notebook Upload</strong>
        Order: {{ data.tracker_id }}
        <br>
        Company: {{ data.company_name }}
        <br>
        PO: {{ data.distributor_po }}
        <br>
        Job Tag: {{ data.job_tag }}
        <br>
        <br>

        <div class="form-group">
            <label>Title</label>
            <input type="text" v-model="state.fileName" class="form-control" />
        </div>
    </div>
</template>

<script>
    import { fileUploadStore } from "@/store/FileUpload.store";
    import appStore from "@/store/App.store";

    export default {
        name: "TrackerNotebook",
        data() {
            return {
                state: fileUploadStore.state
            }
        },
        created() {
            fileUploadStore.initialize();
            fileUploadStore.state.allowAttach = true;
        },
        computed: {
            data: function () {
                if (appStore.state.fileUploadContextData.metadata) {
                    return appStore.state.fileUploadContextData.metadata;
                }

                return appStore.state.fileUploadContextData.data;
            }
        }
    }
</script>

<style scoped>

</style>